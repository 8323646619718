import {NAVBAR_HEIGHT} from "../config/measurements";

import {AppMenuItem} from ".";
import Grid from "@mui/material/Grid";
import MenuList from "@mui/material/MenuList";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const AppMenu = ({children = null, menuItems = [], menuSecondaryItems = []}) => {
    const leftSideItems = menuItems.map((menuItem, index) => (
        <AppMenuItem
            key={index}
            text={menuItem.name}
            to={menuItem.to}
            resolvedPathName={menuItem.pathname}
            resolvedSecondPathName={menuItem.second_pathname || menuItem.pathname}
        />
    ));

    const leftSideSecondaryItems = [
        <Divider orientation="vertical" flexItem key="divider" />,
        ...(menuSecondaryItems
            ? menuSecondaryItems.map((menuItem, index) => (
                  <AppMenuItem
                      key={index}
                      text={menuItem.name}
                      to={menuItem.to}
                      resolvedPathName={menuItem.pathname}
                      resolvedSecondPathName={
                          menuItem.second_pathname || menuItem.pathname
                      }
                      // textStyle={{fontSize: "14px"}}
                  />
              ))
            : []),
    ];

    return (
        <Stack
            direction="row"
            height={`${NAVBAR_HEIGHT}px`}
            sx={{
                width: "100%",
                p: 0,
                borderTop: "1px solid #fff",
            }}
        >
            <MenuList
                sx={{
                    display: "flex",
                    direction: "row",
                    p: 0,
                }}
            >
                {leftSideItems}
                {menuSecondaryItems ? leftSideSecondaryItems : null}
            </MenuList>
            {children}
        </Stack>
    );
};

export default AppMenu;
