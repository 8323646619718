import {useState} from "react";
import {FormInputPassword, FormSection} from "base/form/components";
import {FormProvider, useForm} from "react-hook-form";
import {useAuth} from "base/user/provider";
import {FormUtil} from "base/form/utilities";
import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

import {ChangePasswordSuccessDialog} from "./ChangePasswordSuccessDialog";

const ChangePasswordForm = ({onCancel}) => {
    const auth = useAuth();
    const [error, setError] = useState("");
    const [openChangePasswordSuccess, setOpenChangePasswordSuccess] = useState(false);

    const onSubmit = data => {
        auth.changePassword(data.oldPassword, data.newPassword)
            .then(result => {
                setOpenChangePasswordSuccess(true);
            })
            .catch(error => {
                const errorMsg = error?.detail || error;
                setError(errorMsg);
            });
    };

    const handleFormSubmit = data => {
        setError("");
        const cleanData = {
            oldPassword: FormUtil.getDataValue(data.oldPassword),
            newPassword: FormUtil.getDataValue(data.newPassword),
            confirmPassword: FormUtil.getDataValue(data.confirmPassword),
        };
        if (cleanData.newPassword !== cleanData.confirmPassword) {
            setError("A senha nova non coincide coa confirmación");
            return;
        }
        onSubmit(cleanData);
    };

    const formMethods = useForm({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        reValidateMode: "onSubmit",
    });

    return (
        <FormProvider {...formMethods}>
            <Stack spacing={1}>
                <AlertError error={error} />
                <ChangePasswordSuccessDialog open={openChangePasswordSuccess} />
                <EntityForm
                    onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                    onCancel={onCancel}
                >
                    <FormSection>
                        <FormInputPassword
                            name="oldPassword"
                            label="Senha atual"
                            rules={{required: "Este campo é obrigatório."}}
                        />
                        <FormInputPassword
                            name="newPassword"
                            label="Senha nova"
                            rules={{required: "Este campo é obrigatório."}}
                        />
                        <FormInputPassword
                            name="confirmPassword"
                            label="Confirmar senha"
                            rules={{required: "Este campo é obrigatório."}}
                        />
                    </FormSection>
                </EntityForm>
            </Stack>
        </FormProvider>
    );
};

export default ChangePasswordForm;
