import {theme} from "Theme";
import {DialogLayout} from "base/dialog/components";

import ChangePasswordForm from "./ChangePasswordForm";

const ChangePasswordDialog = ({isDialogOpen, onClose}) => {
    const dialogContent = <ChangePasswordForm onCancel={onClose} />;

    return (
        <DialogLayout
            dialogTitle="Alterar senha"
            dialogContent={dialogContent}
            isDialogOpen={isDialogOpen}
            style={{backgroundColor: theme.palette.pageBackground.secondary}}
            fullWidth
            maxWidth="sm"
        />
    );
};

export default ChangePasswordDialog;
