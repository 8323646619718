import {theme} from "Theme";
import {useWatch} from "react-hook-form";
import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {MaterialUtil} from "material/utils";
import {FormInputDecimal} from "base/form/components";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const MaterialDataFeesFormFields = ({material}) => {
    const type = useWatch({
        name: "type",
    });

    const MaterialDataLabel = () => {
        return (
            <Stack direction="row" spacing={3}>
                <Chip
                    label={
                        <Typography component="span" fontWeight={600} fontSize={12}>
                            {material.type.toUpperCase()} | {material.quantity}{" "}
                            {MaterialUtil.getUnitsForQuantity(material.type)}
                        </Typography>
                    }
                    variant="outlined"
                    color="primary"
                />
                {MaterialUtil.displayLength(type) && (
                    <Typography
                        variant="overline"
                        color="primary.main"
                        textTransform="none"
                    >
                        Comprimento total:{" "}
                        <Typography variant="overline" fontWeight={600}>
                            {material.length} m
                        </Typography>
                    </Typography>
                )}
            </Stack>
        );
    };

    return (
        <>
            <Stack
                sx={{
                    width: "100%",
                    mb: 2,
                    ml: 1,
                    px: 1,
                    py: 1.5,
                    backgroundColor: theme.palette.primary.lighter,
                    borderRadius: "5px",
                }}
            >
                <div>
                    <MaterialDataLabel />
                </div>
            </Stack>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="fee"
                    label="Taxa de transporte"
                    endAdornment={MaterialUtil.getUnitsForFee(type)}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="price"
                    label="Valor de transporte"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled
                />
            </Grid>
        </>
    );
};

export default MaterialDataFeesFormFields;
