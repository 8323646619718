import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {useEffect, useState} from "react";
import {useAuth} from "base/user/provider";
import {theme} from "Theme";
import {DialogLayout} from "base/dialog/components";

export const ChangePasswordSuccessDialog = ({open}) => {
    const [counter, setCounter] = useState(3);
    const auth = useAuth();

    useEffect(() => {
        if (open) {
            const timer = setInterval(() => {
                setCounter(prevCounter => prevCounter - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [open]);

    useEffect(() => {
        if (counter === 0) {
            auth.logout();
        }
    }, [counter]);

    return (
        <DialogLayout
            dialogTitle="Alterar senha"
            dialogContentText={`Sua senha foi alterada com sucesso. Você será redirecionado e desconectado em ${counter} segundos.`}
            isDialogOpen={open}
            style={{backgroundColor: theme.palette.pageBackground.secondary}}
            fullWidth
            maxWidth="sm"
        />
    );
};
