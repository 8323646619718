import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Password from "@mui/icons-material/Password";
import {ChangePasswordDialog} from "base/user/components";

export function useChangePassword() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClickClose = () => {
        setIsDialogOpen(false);
    };

    const menuItem = (
        <MenuItem onClick={handleClickOpen}>
            <ListItemIcon>
                <Password fontSize="small" />
            </ListItemIcon>
            Alterar senha
        </MenuItem>
    );

    const dialog = (
        <ChangePasswordDialog isDialogOpen={isDialogOpen} onClose={handleClickClose} />
    );

    return {menuItem, dialog};
}
