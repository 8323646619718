/**
 * FormDatePicker component is a wrapper around the MUI DatePicker component
 * integrated with react-hook-form for form validation and state management.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name of the form field.
 * @param {string} props.label - The label for the date picker.
 * @param {Object} [props.rules={}] - Validation rules for the form field.
 * @param {Function} [props.onChangeHandler=null] - Optional handler for change events.
 * @param {boolean} [props.disabled=false] - Whether the date picker is disabled.
 * @param {boolean} [props.disableFuture=true] - Whether to disable future dates.
 * @param {Array} [props.views=["year", "month", "day"]] - The views to show in the date picker.
 * @param {Date} [props.minDate=new Date(1990, 0, 1)] - The minimum selectable date.
 * @param {Date} [props.maxDate=new Date(2099, 11, 31)] - The maximum selectable date.
 *
 * @returns {JSX.Element} The rendered FormDatePicker component.
 */
import {useController, useFormContext} from "react-hook-form";
import {DateUtil} from "base/format/utilities";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";

const MIN_DATE = new Date(1990, 0, 1);
const MAX_DATE = new Date(2099, 11, 31);
const FormDatePicker = ({
    name: propsName,
    label,
    rules = {},
    onChangeHandler = null,
    disabled = false,
    disableFuture = true,
    views = ["year", "month", "day"],
    minDate = MIN_DATE,
    maxDate = MAX_DATE,
}) => {
    if (disableFuture && maxDate === MAX_DATE) {
        maxDate = DateUtil.getToday();
    }
    const {control, trigger} = useFormContext();

    const {
        field: {onChange, name, value, ref},
        fieldState: {error},
    } = useController({
        name: propsName,
        control,
        rules: {
            ...rules,
            validate: {
                checkDate: value => {
                    if (value) return validateDate(value);
                },
                ...rules.validate,
            },
        },
    });

    const inputLabel = rules && rules["required"] ? `${label} *` : label;

    const validateDate = value => {
        const dateObject = DateUtil.parseDateFromApi(value);
        if (DateUtil.isInvalidDateObject(dateObject)) {
            return "Data não válida.";
        }

        if (dateObject < minDate) {
            return `A data deve ser posterior a ${DateUtil.formatDate(minDate)}.`;
        }
        if (dateObject > maxDate) {
            return `A data deve ser anterior a ${DateUtil.formatDate(maxDate)}.`;
        }

        return true;
    };

    const handleChange = value => {
        const formattedDate = DateUtil.formatDateForAPI(value);
        onChange(formattedDate); // data sent back to hook form
        if (onChangeHandler) {
            onChangeHandler(formattedDate);
        }
        trigger(propsName);
    };

    return (
        <FormControl fullWidth error={Boolean(error)} margin="dense">
            <DatePicker
                name={name}
                value={DateUtil.parseDateFromApi(value)}
                label={inputLabel}
                inputRef={ref}
                onChange={value => {
                    handleChange(value);
                }}
                slotProps={{
                    textField: {
                        variant: "outlined",
                        placeholder: "dd/mm/aaaa",
                        InputLabelProps: {shrink: true},
                        helperText: error?.message,
                        error: Boolean(error),
                    },
                }}
                views={views}
                minDate={minDate}
                maxDate={maxDate}
                disableFuture={disableFuture}
                disabled={disabled}
            />
        </FormControl>
    );
};

export default FormDatePicker;
