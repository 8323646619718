import {useWatch} from "react-hook-form";
import {useDomain} from "snimf/domain/provider";
import {MaterialUtil} from "material/utils";
import {
    FormInputDecimal,
    FormInputInteger,
    FormInputText,
    FormSelect,
} from "base/form/components";

import Grid from "@mui/material/Grid";

const MaterialDataInitialFormFields = ({}) => {
    const {speciesDomain, materialTypeDomain} = useDomain();

    const type = useWatch({
        name: "type",
    });

    return (
        <Grid container direction="row" columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="species"
                    label="Espécie"
                    options={speciesDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
                <FormSelect
                    name="type"
                    label="Tipo"
                    options={materialTypeDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
                {MaterialUtil.displayLength(type) && (
                    <FormInputDecimal
                        name="length"
                        label="Comprimento total das vigas"
                        endAdornment="m"
                        rules={{required: "Este campo é obrigatório."}}
                    />
                )}
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="quality" label="Qualidade" disabled />
                <FormInputInteger
                    name="quantity"
                    label="Quantidade"
                    endAdornment={MaterialUtil.getUnitsForQuantity(type)}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
        </Grid>
    );
};

export default MaterialDataInitialFormFields;
